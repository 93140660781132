<template>
    <div class="w-full h-full grid grid-cols-6">
        <div class="col-span-1 bg-gray-800 border border-default h-full">
            <v-heading size="2">
                Components
            </v-heading>
        </div>
        <div class="col-span-5 border border-default h-full bg-gray-900">
            <v-typography />
            <v-buttons />
            <v-links />
            <v-alert-components />
            <v-accordions />
            <v-tables />
            <v-form-components />
            <v-page-components />
            <v-chart-components />
        </div>
    </div>
</template>

<script>
import Typography from "./fragments/typography.vue";
import Buttons from "./fragments/buttons.vue";
import Links from "./fragments/links.vue";
import PageComponents from "./fragments/page-components.vue";
import Tables from "./fragments/tables.vue";
import FormComponents from "./fragments/forms.vue";
import AlertComponents from "./fragments/alerts.vue";
import ChartComponents from "./fragments/charts.vue";
import Accordions from "./fragments/accordions.vue";

export default {
    components: {
        "v-typography": Typography,
        "v-buttons": Buttons,
        "v-links": Links,
        "v-tables": Tables,
        "v-form-components": FormComponents,
        "v-page-components": PageComponents,
        "v-alert-components": AlertComponents,
        "v-chart-components": ChartComponents,
        "v-accordions": Accordions,
    },
};
</script>
