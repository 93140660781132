<template>
    <div>
        <div>
            <v-heading size="1">
                Charts
            </v-heading>
        </div>
        <v-section-heading>
            Bar Chart
        </v-section-heading>
        <v-chart-container>
            <v-chart-bar :data="data" :options="options" :height="200" />
        </v-chart-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data: {
                datasets: [
                    {
                        backgroundColor: "rgba(156, 163, 175, 1)",
                        borderColor: "rgba(156, 163, 175, 1)",
                        data: [
                            {
                                x: "2021-03-01",
                                y: "0.00",
                            },
                            {
                                x: "2021-04-01",
                                y: "0.00",
                            },
                            {
                                x: "2021-05-01",
                                y: "0.00",
                            },
                            {
                                x: "2021-06-01",
                                y: "0.00",
                            },
                        ],
                        label: "Maximum Hours",
                        type: "bar",
                    },
                    {
                        backgroundColor: [
                            "rgba(127, 29, 29, 1)",
                            "rgba(127, 29, 29, 1)",
                            "rgba(127, 29, 29, 1)",
                            "rgba(127, 29, 29, 1)",
                        ],
                        borderColor: [
                            "rgba(127, 29, 29, 1)",
                            "rgba(127, 29, 29, 1)",
                            "rgba(127, 29, 29, 1)",
                            "rgba(127, 29, 29, 1)",
                        ],
                        data: [
                            {
                                x: "2021-03-01",
                                y: "267.97",
                            },
                            {
                                x: "2021-04-01",
                                y: "293.96",
                            },
                            {
                                x: "2021-05-01",
                                y: "420.07",
                            },
                            {
                                x: "2021-06-01",
                                y: "226.03",
                            },
                        ],
                        label: "Consume Hours",
                        type: "bar",
                    },
                ],
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            stepSize: 10,
                        },
                    }],
                    xAxes: [
                        {
                            type: "time",
                            time: {
                                unit: "month",
                                displayFormats: {
                                    month: "MMM YYYY",
                                },
                            },
                            offset: true,
                            gridLines: {
                                color: "rgba(0, 0, 0, 0)",
                            },
                        },
                    ],
                },
                legend: false,
            },
        };
    },
};
</script>
