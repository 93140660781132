<template>
    <div>
        <div>
            <v-heading size="1">
                Alerts
            </v-heading>
        </div>
        <div class="">
            <v-alert color="success">
                <template v-slot:title>
                    <v-heading :size="4">
                        Success
                    </v-heading>
                </template>
            </v-alert>
            <v-alert color="danger">
                <template v-slot:title>
                    <v-heading :size="4">
                        Error
                    </v-heading>
                </template>
            </v-alert>
        </div>
    </div>
</template>

<script>
export default {

};
</script>
