<template>
    <div>
        <div>
            <v-heading size="1">
                Tables
            </v-heading>
        </div>
        <div class="">
            <v-table>
                <v-table-head>
                    <v-table-row>
                        <v-table-header>
                            <v-heading :size="4">
                                Name
                            </v-heading>
                        </v-table-header>
                        <v-table-header>
                            <v-heading :size="4">
                                Title
                            </v-heading>
                        </v-table-header>
                        <v-table-header>
                            <v-heading :size="4">
                                Email
                            </v-heading>
                        </v-table-header>
                        <v-table-header>
                            <v-heading :size="4">
                                Role
                            </v-heading>
                        </v-table-header>
                    </v-table-row>
                </v-table-head>

                <v-table-body>
                    <v-table-row>
                        <v-table-data>
                            <v-button color="primaryInverse">
                                Jane Cooper
                            </v-button>
                        </v-table-data>
                        <v-table-data>
                            <v-text>
                                Regional Paradigm Technician
                            </v-text>
                        </v-table-data>
                        <v-table-data>
                            <v-text>
                                jane.cooper@example.com
                            </v-text>
                        </v-table-data>
                        <v-table-data>
                            <v-text>
                                Admin
                            </v-text>
                        </v-table-data>
                    </v-table-row>
                </v-table-body>
            </v-table>
        </div>
    </div>
</template>

<script>
export default {

};
</script>
