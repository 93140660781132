<template>
    <div>
        <div>
            <v-heading size="1">
                Form Components
            </v-heading>
        </div>
        <div>
            <v-form-container>
                <template #title>
                    Profile
                </template>

                <template #description>
                    This information will be displayed publicly so be careful what you share.
                </template>

                <v-form action="#" method="POST">
                    <v-form-group>
                        <v-form-label for="company_website">
                            Website
                        </v-form-label>
                        <v-form-input type="text" name="company_website" placeholder="https://www.example.com" />
                    </v-form-group>
                    <v-form-group>
                        <v-form-label for="company_website">
                            Country / Region
                        </v-form-label>
                        <v-form-select name="country" :options="selectOptions" />
                    </v-form-group>
                    <v-form-group>
                        <v-form-label for="about">
                            About
                        </v-form-label>
                        <v-form-textarea id="about" name="about" />
                    </v-form-group>
                </v-form>
            </v-form-container>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectOptions: [
                {
                    label: "United States",
                    value: "US",
                },
                {
                    label: "Canada",
                    value: "CA",
                },
                {
                    label: "Mexico",
                    value: "MX",
                },
            ],
        };
    },
};
</script>
