<template>
    <div>
        <div>
            <v-heading size="1">
                Page Components
            </v-heading>
        </div>
        <div class="flex flex-col">
            <v-section-heading>
                Section Heading
            </v-section-heading>
        </div>
        <div class="flex flex-col">
            <v-section-toolbar>
                <v-button>
                    Section Toolbar
                </v-button>
            </v-section-toolbar>
        </div>
    </div>
</template>

<script>
export default {

};
</script>
