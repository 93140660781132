<template>
    <div>
        <v-heading size="1">
            Headings
        </v-heading>
        <div class="flex items-center flex-wrap">
            <v-heading size="1">
                Heading 1
            </v-heading>
            <v-heading size="2">
                Heading 2
            </v-heading>
            <v-heading size="3">
                Heading 3
            </v-heading>
            <v-heading size="4">
                Heading 4
            </v-heading>
            <v-heading size="5">
                Heading 5
            </v-heading>
            <v-heading size="6">
                Heading 6
            </v-heading>
        </div>
        <div class="flex items-center flex-wrap">
            <v-heading size="1" color="danger">
                Heading 1 (Danger)
            </v-heading>
            <v-heading size="2" color="danger">
                Heading 2 (Danger)
            </v-heading>
            <v-heading size="3" color="danger">
                Heading 3 (Danger)
            </v-heading>
            <v-heading size="4" color="danger">
                Heading 4 (Danger)
            </v-heading>
            <v-heading size="5" color="danger">
                Heading 5 (Danger)
            </v-heading>
            <v-heading size="6" color="danger">
                Heading 6 (Danger)
            </v-heading>
        </div>
        <v-heading size="1">
            Paragraphs
        </v-heading>
        <div class="flex items-center flex-wrap">
            <v-paragraph>
                (default)
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laborum voluptate voluptatibus illum voluptates exercitationem ducimus officia mollitia aliquid odit et. Doloribus soluta aspernatur odio facere, officiis sit delectus et ex.
            </v-paragraph>
            <v-paragraph size="sm">
                (sm) Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laborum voluptate voluptatibus illum voluptates exercitationem ducimus officia mollitia aliquid odit et. Doloribus soluta aspernatur odio facere, officiis sit delectus et ex.
            </v-paragraph>
        </div>
    </div>
</template>

<script>
export default {

};
</script>
