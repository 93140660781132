<template>
    <div>
        <div>
            <v-heading size="1">
                Accordions
            </v-heading>
        </div>
        <v-accordion>
            <v-accordion-item>
                <template v-slot:title>
                    Accordion Title
                </template>
                <template v-slot:content>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ab unde, error ipsam dignissimos pariatur fugiat temporibus quisquam eaque consequatur vitae ea molestiae exercitationem debitis cumque ipsum, quam nesciunt quasi consectetur?
                </template>
            </v-accordion-item>
        </v-accordion>
    </div>
</template>

<script>
export default {

};
</script>
