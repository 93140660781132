<template>
    <div>
        <div>
            <v-heading size="1">
                Links
            </v-heading>
        </div>
        <div class="flex items-center">
            <v-link>
                Default
            </v-link>
        </div>
    </div>
</template>

<script>
export default {

};
</script>
