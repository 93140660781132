<template>
    <div>
        <div>
            <v-heading size="1">
                Buttons
            </v-heading>
        </div>
        <div class="flex items-center flex-wrap">
            <v-button color="primary">
                Primary
            </v-button>
            <v-button color="primaryInverse">
                Primary Inverse
            </v-button>
            <v-button color="secondary">
                Secondary
            </v-button>
            <v-button color="secondaryInverse">
                Secondary Inverse
            </v-button>
            <v-button color="dark">
                Dark
            </v-button>
        </div>
    </div>
</template>

<script>
export default {

};
</script>
